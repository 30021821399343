import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-know-more-pro',
  templateUrl: './know-more-pro.component.html',
  styleUrls: ['./know-more-pro.component.scss'],
})
export class KnowMoreProComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
