import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { DisplayComponent } from './display/display.component';
import { OurPurposeComponent } from './our-purpose/our-purpose.component';
import { ForPlayersComponent } from './for-players/for-players.component';
import { RealStoriesComponent } from './real-stories/real-stories.component';
import { StoryCardComponent } from './story-card/story-card.component';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from './shared';
import { RouterModule } from '@angular/router';
import { ForScoutsComponent } from './for-scouts/for-scouts.component';
import { FooterComponent } from './footer/footer.component';
import { PlayerDisplayComponent } from './player-display/player-display.component';
import { CreateShareComponent } from './create-share/create-share.component';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { ConnectComponent } from './connect/connect.component';
import { DiscoverComponent } from './discover/discover.component';
import { KnowMoreProComponent } from './know-more-pro/know-more-pro.component';
import { ScoutDisplayComponent } from './scout-display/scout-display.component';
import { ScoutAddComponent } from './scout-add/scout-add.component';
import { ScoutDiscoverComponent } from './scout-discover/scout-discover.component';
import { ScoutCommunicateComponent } from './scout-communicate/scout-communicate.component';
import { PlayerOrParentComponent } from './player-or-parent/player-or-parent.component';
import { BeASponsorComponent } from './be-a-sponsor/be-a-sponsor.component';
import { DiarioAsComponent } from './diario-as/diario-as.component';
import { CifalUnitarOnuComponent } from './cifal-unitar-onu/cifal-unitar-onu.component';
import { PartnersDisplayComponent } from './partners-display/partners-display.component';
import { RegisterComponent } from './register/register.component';
import { CookiesBarComponent } from './cookies-bar/cookies-bar.component';
import { ContactComponent } from './contact/contact.component';
import { WorkWithUsComponent } from './work-with-us/work-with-us.component';
import { PartnerFormComponent } from './partner-form/partner-form.component';


@NgModule({
  declarations: [
    HeaderComponent,
    DisplayComponent,
    OurPurposeComponent,
    ForPlayersComponent,
    RealStoriesComponent,
    StoryCardComponent,
    ForScoutsComponent,
    FooterComponent,
    PlayerDisplayComponent,
    CreateShareComponent,
    MobileMenuComponent,
    ConnectComponent,
    DiscoverComponent,
    KnowMoreProComponent,
    ScoutDisplayComponent,
    ScoutDiscoverComponent,
    ScoutAddComponent,
    ScoutCommunicateComponent,
    PlayerOrParentComponent,
    BeASponsorComponent,
    DiarioAsComponent,
    CifalUnitarOnuComponent,
    PartnersDisplayComponent,
    RegisterComponent,
    CookiesBarComponent,
    ContactComponent,
    WorkWithUsComponent,
    PartnerFormComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    SharedModule,
    ReactiveFormsModule,
    RouterModule
  ],
  exports: [
    HeaderComponent,
    DisplayComponent,
    OurPurposeComponent,
    ForPlayersComponent,
    RealStoriesComponent,
    StoryCardComponent,
    ForScoutsComponent,
    FooterComponent,
    PlayerDisplayComponent,
    CreateShareComponent,
    MobileMenuComponent,
    ConnectComponent,
    DiscoverComponent,
    KnowMoreProComponent,
    ScoutDisplayComponent,
    ScoutDiscoverComponent,
    ScoutAddComponent,
    ScoutCommunicateComponent,
    PlayerOrParentComponent,
    BeASponsorComponent,
    DiarioAsComponent,
    CifalUnitarOnuComponent,
    PartnersDisplayComponent,
    RegisterComponent,
    CookiesBarComponent,
    ContactComponent,
    WorkWithUsComponent,
    PartnerFormComponent
  ]
})
export class ComponentsModule { }
