import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partners-display',
  templateUrl: './partners-display.component.html',
  styleUrls: ['./partners-display.component.scss'],
})
export class PartnersDisplayComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
