import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scout-add',
  templateUrl: './scout-add.component.html',
  styleUrls: ['./scout-add.component.scss'],
})
export class ScoutAddComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
