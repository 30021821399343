import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scout-communicate',
  templateUrl: './scout-communicate.component.html',
  styleUrls: ['./scout-communicate.component.scss'],
})
export class ScoutCommunicateComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
