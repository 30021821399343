import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
  selector: 'app-player-display',
  templateUrl: './player-display.component.html',
  styleUrls: ['./player-display.component.scss'],
})
export class PlayerDisplayComponent implements OnInit {

  constructor(
    private AnalyticsService:AnalyticsService
  ) { }

  ngOnInit() {}

  async eventAnalitycs(event:string, params = {}){
    await this.AnalyticsService.logEventGA4(event,params)
  }
}
