import { Component, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Device } from '@capacitor/device';
import { Router } from '@angular/router';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  sections = ['Scouz Player', 'Scouz Pro', 'Partners', 'Descargar App'];

  constructor(
    private nav: NavController,
    private router: Router,
    private AnalyticsService:AnalyticsService
  ) { }

  async goTo(section: string) {
    switch (section) {
      case 'Scouz Player': {
        await this.nav.navigateForward(['/scouz-player']);
        break;
      }
      case 'Scouz Pro': {
        await this.nav.navigateForward(['/scouz-pro']);
        break;
      }
      case 'Partners': {
        await this.nav.navigateForward(['/partners']);
        break;
      }
      case 'Descargar App': {
        await this.AnalyticsService.logEventGA4('WEB_CLICK_DOWNLOAD',{'store':'auto'})
        window.location.href = 'https://app.scouz.com/download?utm_source=scouz&utm_medium=direct&utm_campaign=lanzamiento&utm_content=secciondescargarapp'
        /*
        const info = await Device.getInfo();
        const platform = info.operatingSystem;
        let link = document.createElement('a');
        link.target = '_blank';
        if (platform === 'ios') {
          const link = document.createElement('a');
          link.href = 'https://apps.apple.com/es/app/scouz-by-as/id1578239404';
        } else if (platform === 'android') {
          link.href = 'https://play.google.com/store/apps/details?id=scouz.player.app';
        } else {
          link.href = 'https://app.scouz.com';
        }
        link.click();
        */
        break;
      }
    }
  }



  isActive(section: string) {
    const url = window.location.pathname;
    if (section === 'Scouz Player' && url === '/scouz-player') {
      return true;
    } else if (section === 'Scouz Pro' && url === '/scouz-pro') {
      return true;
    } else if (section === 'Partners' && url === '/partners') {
      return true;
    } else {
      return false;
    }

  }

}
