import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-purpose',
  templateUrl: './our-purpose.component.html',
  styleUrls: ['./our-purpose.component.scss'],
})
export class OurPurposeComponent implements OnInit {
  cardsList = [
    {
      title: 'FUTBOLISTAS JÓVENES',
      count: '923.805'
    }, {
      title: 'OJEADORES / CAZATALENTOS',
      count: '5.365'
    }, {
      title: 'AGENCIAS DE REPRESENTACIÓN',
      count: '2.789'
    }, {
      title: 'CLUBES / EQUIPOS',
      count: '59.059'
    }
  ];
  
  constructor() { }

  ngOnInit() { }

}
