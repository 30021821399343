import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { Device } from '@capacitor/device';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-real-stories',
  templateUrl: './real-stories.component.html',
  styleUrls: ['./real-stories.component.scss'],
})
export class RealStoriesComponent implements OnInit {
  slideOpts = {
    initialSlide: 1,
    speed: 900,
    // loop: true,
    slidesPerView: 1.15, // 1.3 -> 2 -> 1.15
    spaceBetween: 10,
    centeredSlides: true,
    breakpoints: {
      390: {
        slidesPerView: 1.4
      },
      485: {
        slidesPerView: 1.7
      },
      900: {
        slidesPerView: 2
      },
      970: {
        slidesPerView: 1.1
      },
      1000: {
        slidesPerView: 1.15
      },
      1200: {
        slidesPerView: 1.3
      },
    }
  };
  stories: any[];
  activeSlide = 0;
  platform: string;

  @ViewChild('slides') slides: IonSlides;

  constructor(private firebaseService: FirebaseService) { }

  async ngOnInit() {
    await this.getStories();
    const info = await Device.getInfo();
    this.platform = info.operatingSystem;
    // const resp: any = await this.addVideoViews(this.stories[this.activeSlide]);
    // if (resp) {
    //   await this.getStories();
    // }
  }

  async setActiveSlide() {
    // this.activeSlide = ((await this.slides.getActiveIndex()) - 1);
    let index = await this.slides.getActiveIndex();
    // index -= 2;
    // if (index < 0) {
    //   index += 3;
    // } else if (index > 2) {
    //   index -= 3;
    // }
    const resp: any = await this.addVideoViews(this.stories[index]);
    if (resp) {
      await this.getStories();
    }
  }

  async ionViewWillEnter() {
    const info = await Device.getInfo();
    this.platform = info.operatingSystem;
  }

  async getStories() {
    const resp: any = await this.firebaseService.getStories();
    if (resp && resp.length > 0) {
      if (!this.stories) {
        this.stories = resp;
      } else {
        resp.forEach(elem => {
          const story = this.stories.find(e => e.id === elem.id);
          story.likes = elem.likes;
          story.views = elem.views;
        });
      }
    }
  }

  async addVideoViews(story: any) {
    if (story) {
      story.views = story.views + 1;
      const resp: any = await this.firebaseService.updateVideo(story);
      return resp;
    }
  }

}
