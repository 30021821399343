import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FirebaseService } from 'src/app/services/firebase.service';
import { UtilsService } from 'src/app/services/utils-service.service';
import { LegalPageModule } from 'src/app/pages/legal/legal.module';
import { LegalPage } from 'src/app/pages/legal/legal.page';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  confirm = false;

  constructor(
    private modal: ModalController,
    private modal2: ModalController,
    private firebaseService: FirebaseService,
    private utils: UtilsService,
  ) {
    this.contactForm = new FormGroup({
      name: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      text: new FormControl('', Validators.required),
      type: new FormControl('contact')
    });
  }

  ngOnInit() { }

  close() {
    this.modal.dismiss();
  }

  async send() {
    if (this.contactForm.valid) {
      if (this.confirm) {
        const resp: any = await this.firebaseService.generalCreate(this.contactForm.value);
        if (resp) {
          await this.utils.showToast({ header: '¡Éxito!', message: 'Mensaje correctamente', cssClass: 'success' });
          this.close();
        } else {
          await this.utils.showToast({ header: '¡Error!', message: 'No ha sido posible enviar el mensaje', cssClass: 'danger' });
        }
      } else {
        await this.utils.showToast({ header: '¡Error!', message: 'Debes aceptar la política de privacidad', cssClass: 'danger' });
      }
    } else {
      const formList = ['name', 'email', 'text'];
      const errors = [];
      formList.forEach(async elem => {
        if (this.contactForm.controls[elem].status === 'INVALID') {
          if (elem === 'name') {
            elem = 'nombre';
          } else if (elem === 'text') {
            elem = 'mensaje';
          }
          errors.push(elem);
        }
      });
      if (errors.length > 0) {
        await this.utils.showToast({ header: '¡Error!', message: 'Debes completar el ' + errors[0] + ' correctamente', cssClass: 'danger' });
      }
    }
  }

  async openTerms() {
    const modal = await this.modal2.create({
      component: LegalPage,
      componentProps: {
        isModal: true
      }
      // cssClass: 'my-custom-class'
    });
    return await modal.present();
  }


}
