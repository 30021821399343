import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
  selector: 'app-for-players',
  templateUrl: './for-players.component.html',
  styleUrls: ['./for-players.component.scss'],
})
export class ForPlayersComponent implements OnInit {

  constructor(
    private AnalyticsService:AnalyticsService
  ) { }

  ngOnInit() {}

  async eventAnalitycs(event:string, params = {}){
    await this.AnalyticsService.logEventGA4(event,params)
  }
}
