import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: 'scouz-player',
    loadChildren: () => import('./pages/scouz-player/scouz-player.module').then(m => m.ScouzPlayerPageModule)
  },
  {
    path: 'scouz-pro',
    loadChildren: () => import('./pages/scouz-pro/scouz-pro.module').then(m => m.ScouzProPageModule)
  },
  {
    path: 'partners',
    loadChildren: () => import('./pages/partners/partners.module').then(m => m.PartnersPageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/legal/legal.module').then(m => m.LegalPageModule),
    data: {
      type: 'terms'
    }
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/legal/legal.module').then(m => m.LegalPageModule),
    data: {
      type: 'privacy-policy'
    }
  },
  {
    path: 'cookies',
    loadChildren: () => import('./pages/legal/legal.module').then(m => m.LegalPageModule),
    data: {
      type: 'cookies'
    }
  },
  {
    path: 'terms-rrss',
    loadChildren: () => import('./pages/legal/legal.module').then(m => m.LegalPageModule),
    data: {
      type: 'terms-rrss'
    }
  },
  {
    path: 'quienes-somos',
    loadChildren: () => import('./pages/legal/legal.module').then(m => m.LegalPageModule),
    data: {
      type: 'quienes-somos'
    }
  },
  {
    path: 'faqs',
    loadChildren: () => import('./pages/faqs/faqs.module').then(m => m.FaqsPageModule)
  },
  {
    path: 'partners-form',
    loadChildren: () => import('./pages/partners-form/partners-form.module').then( m => m.PartnersFormPageModule)
  },
  {
   path: 'tu-mejor-video',
    loadChildren: () => import('./pages/promotion/tu-mejor-video/tu-mejor-video.module').then( m => m.TuMejorVideoPageModule)
  },
  {
    path: 'bases-jugador-de-la-semana',
    loadChildren: () => import('./pages/promotion/bases-jugador-de-la-semana/bases-jugador-de-la-semana.module').then( m => m.BasesJugadorDeLaSemanaPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
