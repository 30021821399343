import { Component, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FirebaseService } from 'src/app/services/firebase.service';
import { UtilsService } from 'src/app/services/utils-service.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { LegalPage } from 'src/app/pages/legal/legal.page';

@Component({
  selector: 'app-work-with-us',
  templateUrl: './work-with-us.component.html',
  styleUrls: ['./work-with-us.component.scss'],
})
export class WorkWithUsComponent implements OnInit {
  workForm: FormGroup;
  confirm = false;
  isHybrid = false;

  constructor(
    private modal: ModalController,
    private modal2: ModalController,
    private firebaseService: FirebaseService,
    private utils: UtilsService,
    private platform: Platform,
    private storage: AngularFireStorage
  ) {
    this.workForm = new FormGroup({
      name: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      salary: new FormControl(''),
      experience: new FormControl(''),
      cv: new FormControl(''),
      type: new FormControl('work'),
    });
  }

  ngOnInit() {
    if (this.platform.is('hybrid')) {
      this.isHybrid = true;
    }
  }


  close() {
    this.modal.dismiss();
  }

  async send() {
    if (this.workForm.valid) {
      if (this.confirm) {
        const resp: any = await this.firebaseService.generalCreate(this.workForm.value);
        if (resp) {
          await this.utils.showToast({ header: '¡Éxito!', message: 'Solicitud enviada correctamente', cssClass: 'success' });
          this.close();
        } else {
          await this.utils.showToast({ header: '¡Error!', message: 'No ha sido posible enviar la solicitud', cssClass: 'danger' });
        }
      } else {
        await this.utils.showToast({ header: '¡Error!', message: 'Debes aceptar la política de privacidad', cssClass: 'danger' });
      }
    } else {
      const formList = ['name', 'lastName', 'phone', 'email'];
      const errors = [];
      formList.forEach(async elem => {
        if (this.workForm.controls[elem].status === 'INVALID') {
          if (elem === 'name') {
            elem = 'nombre';
          } else if (elem === 'lastName') {
            elem = 'apellido';
          } else if (elem === 'phone') {
            elem = 'teléfono';
          }
          errors.push(elem);
        }
      });
      if (errors.length > 0) {
        await this.utils.showToast({ header: '¡Error!', message: 'Debes completar el ' + errors[0] + ' correctamente', cssClass: 'danger' });
      }
    }

  }



  async onChangePhoto(e: any): Promise<void> {
    await this.utils.showLoading();
    const file = e.target.files[0];
    const filePath = `cv/${file.name}`;
    const resp: any = await this.storage.upload(filePath, file);
    try {
      if (resp) {
        const url = await resp.ref.getDownloadURL();
        this.workForm.get('cv').setValue(url);
        await this.utils.showToast({ header: '¡Éxito!', message: 'Archivo subido correctamente', cssClass: 'success' });
      }
    } catch (error) {
      await this.utils.showToast({ header: '¡Error!', message: 'No ha sido posible guardar el archivo', cssClass: 'danger' });
    }
    await this.utils.hideLoading();

  }
  async openTerms() {
    const modal = await this.modal2.create({
      component: LegalPage,
      componentProps: {
        isModal: true
      }
      // cssClass: 'my-custom-class'
    });
    return await modal.present();
  }

}
