import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IonInput, LoadingController, ModalController } from '@ionic/angular';
import { Countries } from 'src/app/static_data/countries';
import { FormGroup, FormControl, Validators, AbstractControl, ValidatorFn, ValidationErrors, FormArray } from '@angular/forms';
import { FirebaseService } from 'src/app/services/firebase.service';
import { UtilsService } from 'src/app/services/utils-service.service';
import { LegalPage } from 'src/app/pages/legal/legal.page';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  private fileCertificadoDelitos: File;
  private fileCertificadoDni: File;
  private loading;
  public inviteFriens:boolean = false;
  countries = Countries;
  registerForm: FormGroup;
  confirm = false;
  showCategoryInput = false;
  showContactPersonTitle = false;

  @ViewChild('categoryInput') categoryInput: IonInput
  @ViewChild('personInput') personInput: IonInput

  constructor(
    private modal: ModalController,
    private modal2: ModalController,
    private firebaseService: FirebaseService,
    private utils: UtilsService,
    private loadingC: LoadingController
  ) {
    
    this.registerForm = new FormGroup({
      fullName: new FormControl('', Validators.required),
      nif: new FormControl('', Validators.required),
      entity: new FormControl('',Validators.required),
      contactPersonTitle: new FormControl('',Validators.required),
      phone: new FormControl('', Validators.required),
      country: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      comment: new FormControl(''),
      type: new FormControl('register'),
      created: new FormControl(new Date()),
      certificadoDelitos: new FormControl(null),
      certificadoDni: new FormControl(null),
      socialNetworks: new FormGroup({
        facebook: new FormControl(''),
        instagram: new FormControl(''),
        twitter: new FormControl(''),
        tiktok: new FormControl(''),
        linkedin: new FormControl('')
        },
        { validators: [this.validateSocialNetworks] }
      ),
      inviteFriends: new FormControl(null),
      inviteFriendsComment: new FormControl(''),
      inviteFriendsEmails:new FormArray([]),
      
    });
    
    
    
  }

  ngOnInit() { }

  close() {
    this.modal.dismiss();
  }

  async send() {
    if(this.registerForm.valid) {
      if(this.confirm) {
  
        //subimos los ficheros
        this.loading = await this.loadingC.create({
          message: 'Procesando...',
        });
         await this.loading.present();
         this.registerForm.controls['inviteFriends'].setValue(this.inviteFriens);

        if(this.fileCertificadoDni){
          let nameDni: string = await this.firebaseService.uploadDocument(this.fileCertificadoDni)
          this.registerForm.controls['certificadoDni'].setValue(nameDni);
        }


        if(this.fileCertificadoDelitos){
          let nameCertificado: string = await this.firebaseService.uploadDocument(this.fileCertificadoDelitos)
          this.registerForm.controls['certificadoDelitos'].setValue(nameCertificado);
        }
        this.loading.dismiss();



       

        const resp: any = await this.firebaseService.generalCreate(this.registerForm.value);
        if (resp) {
          await this.utils.showToast({ header: '¡Éxito!', message: 'Solicitud enviada correctamente', cssClass: 'success' });
          this.loading.dismiss();
          this.close();
        } else {
          this.loading.dismiss();
          await this.utils.showToast({ header: '¡Error!', message: 'No ha sido posible enviar la solicitud', cssClass: 'danger' });
        }
        
        
      } else {
        await this.utils.showToast({ header: '¡Error!', message: 'Debes aceptar la política de privacidad', cssClass: 'danger' });
      }
      
    } else {
      console.log(this.registerForm)
      const formList = ['fullName', 'nif','country','city','phone', 'email','socialNetworks','certificadoDelitos','inviteFriendsEmails','inviteFriendsComment'];
      const errors = [];
      formList.forEach(async elem => {
        if (this.registerForm.controls[elem].status === 'INVALID') {
          if (elem === 'fullName') {
            elem = 'nombre';
          } else if (elem === 'nif') {
            elem = 'NIF/CIF';
          }else if (elem === 'phone') {
            elem = 'teléfono';
          }else if (elem === 'city') {
            elem = 'ciudad';
          }else if (elem === 'country') {
            elem = 'pais';
          }else if (elem === 'socialNetworks') {
            elem = 'redes sociales';
          }else if (elem === 'entity') {
            elem = 'club/entidad/empresa';
          }else if (elem === 'contactPersonTitle') {
            elem = 'cargo';
          }else if (elem === 'inviteFriendsEmails') {
            elem = 'inviteFriendsEmails';
          }else if (elem === 'inviteFriendsComment') {
            elem = 'inviteFriendsComment';
          }
          errors.push(elem);
        }
      });

      //validamos los emails
      console.log(this.registerForm.controls['inviteFriendsEmails'].status)
      
      if (errors.length > 0) {
        console.log(errors[0]);
        if(errors[0] === "redes sociales"){
          await this.utils.showToast({ header: '¡Error!', message: 'Debes completar una de tus redes sociales correctamente', cssClass: 'danger' });
        }
        else if(errors[0] === "certificadoDelitos"){
          await this.utils.showToast({ header: '¡Error!', message: 'Debes subir el certificado de delitos de Natural Sexual', cssClass: 'danger' });
        }
        else if(errors[0] === "inviteFriendsEmails"){
          await this.utils.showToast({ header: '¡Error!', message: 'Debe escribir correctamente todos los emails de sus amigos', cssClass: 'danger' });
        }
        else if(errors[0] === "inviteFriendsComment"){
          await this.utils.showToast({ header: '¡Error!', message: 'Debe complementar el comentario que va a indicar a sus amigos', cssClass: 'danger' });
        }
        else{
          await this.utils.showToast({ header: '¡Error!', message: 'Debes completar el ' + errors[0] + ' correctamente', cssClass: 'danger' });
        }
      }
    }
   
  }

  showInputs(ev: any, type: string) {
    if (ev.detail.value === 'Otros') {
      if (type === 'category') {
        this.showCategoryInput = true;
        this.registerForm.get('category').setValue('');
        setTimeout(() => {
          this.categoryInput.setFocus();
        }, 500);
      } else if (type === 'personContact') {
        this.showContactPersonTitle = true;
        this.registerForm.get('contactPersonTitle').setValue('');
        setTimeout(() => {
          this.personInput.setFocus();
        }, 500);
      }
    }

  }

  showInviteFriends(ev: any) {
    
    if (ev.detail.checked === true) {
      this.inviteFriens=true
      const creds = this.registerForm.get('inviteFriendsEmails') as FormArray;
      creds.push(new FormGroup({
        emailFriends: new FormControl('',[Validators.required, Validators.email])
      }));
      this.registerForm.controls["inviteFriendsComment"].setValidators([Validators.required]);
      this.registerForm.controls["inviteFriendsComment"].updateValueAndValidity()
    }
    else{
      this.inviteFriens=false
      const creds = this.registerForm.get('inviteFriendsEmails') as FormArray;
      for (let i = 0; i < creds.length; i++) {
        creds.removeAt(0)
      }
      this.registerForm.controls["inviteFriendsComment"].setValidators([]);
      this.registerForm.controls["inviteFriendsComment"].updateValueAndValidity()
    }

  }

  addEmailInvites(){
    const creds = this.registerForm.get('inviteFriendsEmails') as FormArray;
    creds.push(new FormGroup({
      emailFriends: new FormControl('',[Validators.required, Validators.email])
    }));
  }

  borrarEmail(i:number){
    const creds = this.registerForm.get('inviteFriendsEmails') as FormArray;
    creds.removeAt(i)
  }

  async openTerms() {
    const modal = await this.modal2.create({
      component: LegalPage,
      componentProps: {
        isModal: true
      }
      // cssClass: 'my-custom-class'
    });
    return await modal.present();
  }

  validateSocialNetworks: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const { facebook, instagram,twitter,tiktok,linkedin } = control.value; // Extraemos valores de ambos campos necesarios
    if (facebook === ""  && instagram === "" && twitter === "" && tiktok === "" && linkedin === "") {
      return { SocialNetworksRequired: true }
    }
    return null;
  }


  async onFileCertificadoDniChange(fileChangeEvent) {
    this.fileCertificadoDni = fileChangeEvent.target.files[0];
    if (this.fileCertificadoDni.size > 2485760) {
      await this.utils.showToast({ header: '¡Error!', message: 'El fichero debe tener como máximo 2,5 Mg', cssClass: 'danger' });
      this.fileCertificadoDni = null;
    }
   
    
  }

  async onFileCertificadoDelitosChange(fileChangeEvent) {
    this.fileCertificadoDelitos = fileChangeEvent.target.files[0];
    if (this.fileCertificadoDelitos.size > 2485760) {
      await this.utils.showToast({ header: '¡Error!', message: 'El fichero debe tener como máximo 2,5 Mg', cssClass: 'danger' });
      this.fileCertificadoDelitos = null;
    }
  }
}