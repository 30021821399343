import { Component, OnInit } from '@angular/core';
import { Device } from '@capacitor/device';
import { debug } from 'console';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
  selector: 'app-display',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.scss'],
})
export class DisplayComponent implements OnInit {
  platform: string;
  video: any;

  constructor(
    private AnalyticsService:AnalyticsService
  ) { }

  async ngOnInit() {
    const info = await Device.getInfo();
    this.platform = info.operatingSystem;
    if (this.platform === 'ios' || this.platform === 'android') {
      setTimeout(async () => {
        this.video = document.querySelector('video');
        this.video.muted = true;
        await this.video.play()
      }, 500);
    }
  }

  async goTo() {
    await this.AnalyticsService.logEventGA4('WEB_CLICK_DOWNLOAD',{'store':'auto'})
    window.location.href = 'https://app.scouz.com/download?utm_source=scouz&utm_medium=direct&utm_campaign=lanzamiento&utm_content=secciondescargarapp'
    /*
    if (this.platform === 'ios') {
      window.open('https://apps.apple.com/es/app/scouz-by-as/id1578239404', '_blank');
    } else if (this.platform === 'ios') {
      window.open('https://play.google.com/store/apps/details?id=scouz.player.app&hl=es&gl=US', '_blank');
    } else {
      window.open('https://app.scouz.com', '_blank');
    }
    */ 
  }

  async eventAnalitycs(event:string, params = {}){
    await this.AnalyticsService.logEventGA4(event,params)
  }

}
