import { Component } from '@angular/core';
import { StorageService } from './services/storage.service';
import { initializeApp } from "firebase/app";
import { environment } from 'src/environments/environment';
import { AnalyticsService } from './services/analytics.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent {
  hideBar = false;

  constructor(
    private storage: StorageService,
    private analytics: AnalyticsService,
  ) {
    this.getCookiesInfo();
    const app = initializeApp(environment.firebaseConfig);

    this.analytics.initAnalytics();

  }

  async getCookiesInfo() {
    const resp: any = await this.storage.getItem('hideCookiesBar');
    if (resp) {
      this.hideBar = true;
    }
  }


  async cookiesBarAction(ev: boolean) {
    this.hideBar = true;
    await this.storage.setItem('hideCookiesBar', true);
  }

}