import { Component, OnInit } from '@angular/core';
import { MenuController, NavController } from '@ionic/angular';
import { Device } from '@capacitor/device';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent implements OnInit {
  sections = ['Scouz Player', 'Scouz Pro', 'Partners'];
  platform: string;

  constructor(
    private menu: MenuController,
    private nav: NavController,
    private AnalyticsService:AnalyticsService
  ) { }

  async ngOnInit() {
    const info = await Device.getInfo();
    this.platform = info.operatingSystem;
  }

  close() {
    this.menu.close();
  }

  async goTo(section: string) {
    switch (section) {
      case 'Scouz Player': {
        await this.nav.navigateForward(['/scouz-player']);
        break;
      }
      case 'Scouz Pro': {
        await this.nav.navigateForward(['/scouz-pro']);
        break;
      }
      case 'Partners': {
        await this.nav.navigateForward(['/partners']);
        break;
      }
      case 'Descargar App': {
        await this.nav.navigateForward(['/download']);
        break;
      }
      case 'home': {
        await this.nav.navigateForward(['']);
        break;
      }
    }
    this.close();
  }


  async goLink() {
    await this.AnalyticsService.logEventGA4('WEB_CLICK_DOWNLOAD',{'store':'auto'})
    window.location.href = 'https://app.scouz.com/download?utm_source=scouz&utm_medium=direct&utm_campaign=lanzamiento&utm_content=secciondescargarapp'
    /*
    if (this.platform === 'ios') {
      window.open('https://apps.apple.com/es/app/scouz-by-as/id1578239404', '_blank');
    } else if (this.platform === 'ios') {
      window.open('https://play.google.com/store/apps/details?id=scouz.player.app&hl=es&gl=US', '_blank');
    } else {
      window.open('https://app.scouz.com', '_blank');
    }
    */
  }

}
