import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scout-discover',
  templateUrl: './scout-discover.component.html',
  styleUrls: ['./scout-discover.component.scss'],
})
export class ScoutDiscoverComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
