import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-discover',
  templateUrl: './discover.component.html',
  styleUrls: ['./discover.component.scss'],
})
export class DiscoverComponent implements OnInit {
  cards = [
    {
      name: 'jugadores',
      icon: 'players',
      text: 'Busca a ese jugador o jugadora con el que te vas a enfrentar, síguele y descubre cuáles son sus habilidades principales. Descubre el talento futbolístico en cualquier parte del mundo.'
    }, {
      name: 'clubes',
      icon: 'clubs',
      text: 'Sigue al club en el que juegas y descubre las player cards de tus compañeros y compañeras de equipo. Busca a tus equipos rivales y a los clubes a los que admiras, descubre los jóvenes futbolistas que juegan en cada equipo.'
    }, {
      name: 'eventos',
      icon: 'events',
      text: 'Inscríbete en la prueba de ese equipo en el que siempre has soñado jugar. Esta puede ser tu oportunidad para conseguirlo.'
    }
  ];
  constructor() { }

  ngOnInit() { }

}
