import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-cookies-bar',
  templateUrl: './cookies-bar.component.html',
  styleUrls: ['./cookies-bar.component.scss'],
})
export class CookiesBarComponent implements OnInit {

  @Output()
  action: any = new EventEmitter();
  constructor() { }

  ngOnInit() { }

  barAction(data: boolean) {
    this.action.emit(data);
  }

}
