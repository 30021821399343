import { Injectable } from '@angular/core';
import { ToastOptions, AlertOptions, AlertButton } from '@ionic/core';
import { ToastController, LoadingController, AlertController, Platform, ModalController, ActionSheetController, NavController } from '@ionic/angular';
import { Router } from '@angular/router';

// import { TranslateService } from '@ngx-translate/core';
import { ToastParams } from 'src/app/interfaces/other';

import { App } from '@capacitor/app';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  loading: HTMLIonLoadingElement;
  inLoading = false;

  currentPage = '';
  lastPage = '';

  // refreshData: BehaviorSubject<string> = new BehaviorSubject(null);

  defaultToastParams: ToastParams = {
    header: '¡Éxito!',
    // message: this.translateService.instant('general.success_message_op'),
    message: '',
    cssClass: 'success',
    duration: 3000
  };

  constructor(
    private toast: ToastController,
    private loadingCtrl: LoadingController,
    private alert: AlertController,
    private platform: Platform,
    private router: Router,
    private nav: NavController,
    private modal: ModalController,
    private actionSheetCtrl: ActionSheetController,
    // private translateService: TranslateService
  ) { }

  async showToast(tp: ToastParams = this.defaultToastParams, waitDismiss = false) {
    return new Promise(async resolve => {
      const buttons = tp.buttons ? tp.buttons : [];
      const header = tp.header ? tp.header : this.defaultToastParams.header;
      const message = tp.message ? tp.message : this.defaultToastParams.message;
      const duration = tp.duration ? tp.duration : this.defaultToastParams.duration;
      const cssClass = tp.cssClass ? tp.cssClass : this.defaultToastParams.cssClass;

      buttons.push(
        { side: 'start', icon: `/assets/icons/${cssClass}.svg` },
        { side: 'end', icon: 'close' }
      );

      const toastOptions: ToastOptions = {
        header,
        message,
        duration,
        cssClass,
        buttons
      };

      const toast = await this.toast.create(toastOptions);
      await toast.present();
      if (waitDismiss) {
        await toast.onWillDismiss();
      }
      resolve(false);
    });
  }

  async showLoading(duration = 3000, message = '') {
    // if (await this.loadingCtrl.getTop()) {
    //   await this.hideLoading();
    // }

    if (!this.inLoading) {
      this.inLoading = true;
      this.loading = await this.loadingCtrl.create({
        message,
        duration,
        spinner: 'dots',
        cssClass: 'loading'
      });

      await this.loading.present();
    }
  }

  async hideLoading() {
    if (this.loading) {
      this.inLoading = false;
      await this.loading.dismiss();
    }
  }

  async showAlert(header: string, subHeader: string, message: string, buttons: AlertButton[] | string[] = ['Ok'],
    cssClass?: string, inputs?: any[]) {
    const opts: AlertOptions = {
      header,
      message,
      buttons
    };
    if (subHeader) {
      opts.subHeader = subHeader;
    }
    if (cssClass) {
      opts.cssClass = cssClass;
    }
    if (inputs) {
      opts.inputs = inputs;
    }
    const alert = await this.alert.create(opts);

    await alert.present();
  }

  // observeRefreshData() {
  //   return this.refreshData.asObservable();
  // }

  // async setRefreshData() {
  //   this.refreshData.next('refresh');
  // }

  configBackButtonAndroid() {
    this.platform.backButton.subscribeWithPriority(2, async () => {
      if (this.router.url !== '/tabs/home' && this.router.url !== '/pre-login') {
        const mod = await this.modal.getTop();
        if (mod) {
          this.modal.dismiss();
        } else {
          this.nav.back();
        }
      } else {
        App.exitApp();
      }
    });
  }

  async inProgress() {
    await this.showToast({ header: '¡Error!', message: 'In progress', cssClass: 'danger' });
  }

  async showActionSheet(header: string, cssClass: string, buttons: any[]): Promise<void> {
    const options = {
      header,
      cssClass,
      buttons
    };
    const actionSheet = await this.actionSheetCtrl.create(options);
    await actionSheet.present();
  }

  addHeadLink() {
    const node: any = document.createElement('script');

    const url = ``;
    node.async = true;

    node.src = url;
    node.type = 'text/javascript';
    // node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  setCurrentPage(url) {
    this.lastPage = this.currentPage;
    this.currentPage = url;
    // console.error(this.lastPage);
    // console.error(this.currentPage);
  }

  generateId(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }


  viewLastPage() {
    return this.lastPage;
  }
}
