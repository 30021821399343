import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { Legal_texts } from 'src/app/static_data/legal_texts';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.page.html',
  styleUrls: ['./legal.page.scss'],
})
export class LegalPage implements OnInit {
  texts = Legal_texts;
  text: any;

  @Input()
  isModal = false;

  constructor(
    private router: ActivatedRoute,
    private nav: NavController,
    private modal: ModalController,
    private sanitizer: DomSanitizer
  ) {
    if (this.router.snapshot.data && this.router.snapshot.data.type) {
      switch (this.router.snapshot.data.type) {
        case 'privacy-policy': {
          this.text = this.sanitizer.bypassSecurityTrustHtml(this.texts.privacy_policy);
          break;
        }
        case 'cookies': {
          this.text = this.texts.cookies_text;
          break;
        }
        case 'terms': {
          this.text = this.texts.terms;
          break;
        }
        case 'terms-rrss': {
          this.text = this.texts.terms_rrss;
          break;
        }
        case 'quienes-somos': {
          this.text = this.texts.about_us;
          break;
        }
      }
    }
  }

  back() {
    if (this.isModal) {
      this.modal.dismiss();
    } else {
      this.nav.back();
    }
  }

  ngOnInit() {
    if (this.isModal) {
      this.texts;
      this.text = this.texts.privacy_policy;
    }
  }

}
