import { Injectable, Query } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, CollectionReference, DocumentData, QuerySnapshot } from '@angular/fire/compat/firestore';
// import { UtilsService } from '../utils/utils.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import * as firebase from 'firebase/app';
// import { StorageService } from '../storage/storage.service';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  newPhotoUrl: any;
  url: any;

  constructor(
    public db: AngularFirestore,
    private auth: AngularFireAuth,
    // private utils: UtilsService,
    private storage: AngularFireStorage,
    // private storageService: StorageService
  ) { }

  async uploadImage(file: any) {
    const filePicker = file;
    if (!filePicker || !filePicker.files || filePicker.files.length <= 0) {
      return;
    }
    const myFile = filePicker.files[0];
    if (myFile.size > 2485760) {
      // await this.utils.showToast('Imagen muy grande', 3000);
      return;
    }

    this.newPhotoUrl = myFile;
    const resp: any = await this.uploadImg();
    return resp;
  }

  async uploadImg() {
    if (this.newPhotoUrl) {
      try {
        const result = await this.storage.ref('images').child(this.newPhotoUrl.name).put(this.newPhotoUrl);
        this.url = await result.ref.getDownloadURL();
        return this.url;
      } catch (error) {
        console.error(error);
        // await this.utils.showToast('Error al subir la imagen', 3000);
      }
    }
  }


  async uploadDocument(file: any) {

    if (location.hostname === "localhost") {
      // Point to the Storage emulator running on localhost.
      //this.storage.storage.useEmulator("localhost", 9199);
    } 

    let fileExtension:string = file.name.split('?')[0].split('.').pop();
    let fileName=uuidv4()+"."+fileExtension;
    if (file.size > 2485760) {
      return;
    }

    const refFile = await this.storage.ref('fileLanding').child(fileName);
    await refFile.put(file);
   
    return fileName;
  }


  async generalCreate(formData: any) {
    const dataToInsert = formData;
    return new Promise(async resolve => {
      this.db.collection('landing').add(dataToInsert).then(
        async (result: any) => {
          resolve(true);
          // await this.utils.showToast('Extra creado correctamente', 2000);
        }
      ).catch(
        async (error) => {
          resolve(false);
          // await this.utils.showToast('Error al creado la marca', 3000);
        }
      );
    });
  }

  async getStories() {
    let queryRef: CollectionReference = this.db.collection('landing_videos').ref;
    let query: any;
    query = queryRef;
    let queryResult: QuerySnapshot<DocumentData>;
    queryResult = await query.get();
    const results = [];

    if (!queryResult.empty) {
      queryResult.docs.forEach(doc => {
        const insertDoc = doc.data();
        if (!insertDoc.id) {
          insertDoc.id = doc.id;
        }
        results.push(insertDoc);
      });
    }
    return results;
  }

  updateVideo(story: any) {
    return new Promise(async resolve => {
      this.db.collection('landing_videos').doc(story.id).update(story).then(
        async (result: any) => {
          resolve(true);
          // await this.utils.showToast('Extra creado correctamente', 2000);
        }
      ).catch(
        async (error) => {
          resolve(false);
          // await this.utils.showToast('Error al creado la marca', 3000);
        }
      );
    });
  }
}
