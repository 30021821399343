// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  emulator:false,
  firebaseConfig : {
   
    apiKey: 'AIzaSyAlbiN_Cq0UoncP8ClHjDgzPUoKXCa6CgY',
    authDomain: 'scouz-b02d0.firebaseapp.com',
    projectId: 'scouz-b02d0',
    // storageBucket: 'scouz-b02d0.appspot.com',
    storageBucket: 'scouz-b02d0',
    messagingSenderId: '322503186657',
    appId: '1:322503186657:web:fe449e7c216b8fa0ccbfa0',
    measurementId: 'G-TLZE5B0M0P'
    /*DESRROLLO
    apiKey: "AIzaSyCIm2v2aAcKnzcjnQ9vZg5VbV-pyoqWUck",
    authDomain: "scouz-b7e9f.firebaseapp.com",
    databaseURL: "https://scouz-b7e9f-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "scouz-b7e9f",
    storageBucket: "scouz-b7e9f.appspot.com",
    messagingSenderId: "238427818973",
    appId: "1:238427818973:web:74659ea787821940ff0434",
    measurementId: "G-WG8MNWPJB3"
    */
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
