import { Injectable } from '@angular/core';

import { Storage } from '@capacitor/storage';


@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  async setCurrentUser(currentUser: any) {
    return await Storage.set({ key: 'currentUser', value: JSON.stringify(currentUser) });
  }

  async getCurrentUser() {
    const user = await Storage.get({ key: 'currentUser' });
    return JSON.parse(user.value);
  }

  async deleteCurrentUser() {
    return await Storage.remove({ key: 'currentUser' });
  }

  async setItem(key: string, value) {
    await Storage.set({ key, value });
  }

  async getItem(key: string) {
    const elem: any = await Storage.get({ key });
    return elem ? elem.value : null;
  }

  async deleteItem(key: string) {
    await Storage.remove({ key });
    return true;
  }

  async deleteAll() {
    return await Storage.clear();
  }

  async setAccessToken(accessToken) {
    return await Storage.set({ key: 'accessToken', value: accessToken });
  }
}
