import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { RegisterComponent } from '../register/register.component';

@Component({
  selector: 'app-scout-display',
  templateUrl: './scout-display.component.html',
  styleUrls: ['./scout-display.component.scss'],
})
export class ScoutDisplayComponent implements OnInit {

  constructor(private modal: ModalController) { }

  ngOnInit() { }

  async openRegister() {
    const modal = await this.modal.create({
      component: RegisterComponent,
      cssClass: 'register_modal'
    });
    return await modal.present();
  }

}
