import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Device } from '@capacitor/device';
import { environment } from 'src/environments/environment';

declare var gtag: any;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(
    private router: Router
    ) {
  }


  async initAnalytics() {
    console.log('ENABLE ANALITICAS');
    await this.initFb();
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.setScreenName(e.url)
      }
    });
  }

  async initFb() {
    if ((await Device.getInfo()).platform === 'web') {
      await FirebaseAnalytics.initializeFirebase(environment.firebaseConfig);
    }
  }

  async logEventGA4(name = '', params = {}) {
    FirebaseAnalytics.logEvent({ name, params });
  }


  async logEventGA4ActiveApplication(name = '', params = {}) {
    FirebaseAnalytics.logEvent({ name, params });
  }

  async logEventGA4DesactiveApplication(name = '', params = {}) {
    FirebaseAnalytics.logEvent({ name, params });
  }


  logEventLoginGA4method(method = '') {
    FirebaseAnalytics.logEvent({
      name: 'login',
      params: {
        method: method
      }
    });
  }


  async logEventLogoutGA4method(method = '') {
    FirebaseAnalytics.logEvent({
      name: 'logout',
      params: {
        method: method
      }
    });
  }

  logEventRegisterGA4method(method = '') {
    FirebaseAnalytics.logEvent({
      name: 'sign_up',
      params: {
        method: method
      }
    });
  }

  setScreenName(screenName: string) {
    FirebaseAnalytics.setScreenName({ 'screenName': screenName });
  }


  setUserGA4(userId: string) {
    FirebaseAnalytics.setUserId({
      userId: userId,
    });
  }

}

  