import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Device } from '@capacitor/device';
import { FirebaseService } from 'src/app/services/firebase.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-story-card',
  templateUrl: './story-card.component.html',
  styleUrls: ['./story-card.component.scss'],
})
export class StoryCardComponent implements OnInit, OnChanges {
  @Input()
  platform: string;
  @Input()
  story: any;
  @Input()
  isPlay = false;

  video: any;
  isLike = false;
  constructor(
    private storage: StorageService,
    private firebaseService: FirebaseService
  ) { }

  async ngOnInit() {
    if (!this.platform) {
      await this.getPlatform();
    }
    if (!this.video) {
      this.video = document.querySelector('video');
    }
    setTimeout(() => {
      if (this.video) {
        this.video.muted = true;
        if (this.platform !== 'ios' && this.platform !== 'android') {
          setTimeout(async () => {
            const video = document.querySelector('video');
            video.muted = true;
            await video.play();
          }, 100);
        }
      }
    }, 1000);
  }

  async ngOnChanges() {
    const resp: any = await this.storage.getItem(this.story.id);
    if (resp) {
      this.isLike = true;
    }
    if (!this.platform) {
      await this.getPlatform();
    }
    if (!this.video) {
      this.video = document.querySelector('video');
    }
  }

  async playVideo() {
    if (this.video) {
      this.video.muted = true;
      setTimeout(async () => {
        await this.video.play();
      }, 1000);
    }
  }

  async getPlatform() {
    const info = await Device.getInfo();
    this.platform = info.operatingSystem;
  }

  async like() {
    if (!this.isLike) {
      this.story.likes = this.story.likes + 1;
      const resp: any = await this.firebaseService.updateVideo(this.story);
      if (resp) {
        await this.storage.setItem(this.story.id, true);
        const resp2: any = await this.storage.getItem(this.story.id);
        if (resp2) {
          this.isLike = true;
        }
      }
    }
  }

}
