import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ContactComponent } from '../contact/contact.component';
import { WorkWithUsComponent } from '../work-with-us/work-with-us.component';
import { StringDecoder } from 'string_decoder';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  isDark = false;

  constructor(
    private modal: ModalController,
    private AnalyticsService:AnalyticsService
  ) { }

  ngOnInit() {
    this.detectDarkMode();
  }

  detectDarkMode() {
    const result = window.matchMedia('(prefers-color-scheme: dark)');
    if (result.matches) {
      this.isDark = true;
    } else {
      this.isDark = false;
    }
  }

  async contact() {
    const modal = await this.modal.create({
      component: ContactComponent
    });
    return await modal.present();
  }


  async workWithUs() {
    const modal = await this.modal.create({
      component: WorkWithUsComponent
    });
    return await modal.present();
  }

  async eventAnalitycs(event:string, params = {}){
    await this.AnalyticsService.logEventGA4(event,params)
  }

}
