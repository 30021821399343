import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PartnerFormComponent } from '../partner-form/partner-form.component';

@Component({
  selector: 'app-be-a-sponsor',
  templateUrl: './be-a-sponsor.component.html',
  styleUrls: ['./be-a-sponsor.component.scss'],
})
export class BeASponsorComponent implements OnInit {

  constructor(private modal: ModalController) { }

  ngOnInit() { }

  async openForm() {
    const modal = await this.modal.create({
      component: PartnerFormComponent
    });
    return await modal.present();
  }

}
